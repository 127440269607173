@font-face {
    font-family: 'StateFaceRegular';
    src: url(../fonts/stateface-regular-webfont.eot);
    src: url(../fonts/stateface-regular-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/stateface-regular-webfont.woff) format('woff'),
    url(../fonts/stateface-regular-webfont.ttf) format('truetype'),
    url(../fonts/stateface-regular-webfont.svg#StateFaceRegular) format('svg');
    font-weight: normal;
    font-style: normal;
}


body {
    background-color: #f5f5f5;
    overflow-x:hidden;
    width:100%;
    margin-top:50px;
}

a {
    cursor:pointer;
    text-decoration:none !important;
    transition: all .5s;
}
.orange,
.orange a {
    color:#ffbb33;
}

.alert {
    margin:0;
}


.state-face {
    font-family:"StateFaceRegular";
}

.btn {
    border-radius: 0;
}
.btn-moto {
    background-color: #ffbb33;
    border-radius:0;
}


.panel {
    border: none;
    border-radius: 0;
}

.panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


/** Begin CheckBoxes **/
.checkbox {
    padding-left: 20px; }
.checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px; }
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555; }
.checkbox input[type="checkbox"] {
    opacity: 0; }
.checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
.checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c"; }
.checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
    border-radius: 50%; }
.checkbox.checkbox-inline {
    margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #428bca;
    border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
    color: #fff; }
/** END CHECKBOXES**/



/****** About Us Begin ****************/
.about-wrap .about-page div {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 1em 1em 0;
}
.container-separator.about-page {
    margin: 35px 0;
}

/******* About Us End ************/

/************************ BEGIN TRACKS IN STATE STYLE ************************/

#tracks-in-state > li:nth-child(odd) {
    background-color: #F5F5F5;
}
#tracks-in-state > li:nth-child(even) {
    background-color: #FFFFFF;
}
.track-list {
    padding: 15px 5px 5px 10px;
    min-height: 150px;
}

.track-list address {
    font-size: 1.5em;
}
a.track-list-title {
    font-size: 2.5em;
}

#state_map_overlay2 {
    background-color: rgba(0, 0, 0, 0.75);
    height: 200px;
    position: relative;
    padding-top:3px;
    padding-bottom: 25px;
}
#wrappa {
    position:relative;
    width:100vw;
}
#track-list {
    background-color: #FFF;
}

.expand-info {
    margin: 15px 0;
}

.track-list-description {
    padding: 10px 5px;
    font-size: 14px;
}


/************************ END TRACKS IN STATE STYLE ************************/


/************************ BEGIN NAVBAR STYLE ************************/
.navbar-moto {
    border-radius:0;
    border-color:transparent;
    background-image:none !important;
    margin-bottom:0;
    font-weight:bold;
    position:fixed;
    width:100%;
    top:0;
    left:0;
}
.navbar-moto .caret {
    color:#FFF;
}
.nav .btn {
    padding: 6px 12px !important;
    margin-top: 8px;
    margin-left: 8px;
}
.navbar-moto a {
    font-weight: bold;
}
.dropdown-menu>li>a {
    color:#000 !important;
}

.navbar-moto .navbar-brand{
    margin-left: 0 !important;
}

.navbar-moto a {
    text-decoration: none !important;
    color: #FFF !important;
}
.navbar-moto  a:hover {
    color:#337ab7 !important;
}
.navbar-moto .navbar-nav>.open>a,
.navbar-moto .navbar-nav>.open>a:focus,
.navbar-moto .navbar-nav>.open>a:hover {
    background-color: transparent !important;
}
#nav-search {
    font-weight: 300;
}
.reverse-fade,
.filter_on {
    color:#337ab7;
    transition: all .5s;
}
.reverse-fade:hover {
    color:#FFF;
}
.navbar-form .form-control {
    border-radius: 0;
}
#nav-search-results {
    width: 650px;
    min-height: 120px;
    max-height:520px;
    overflow-x:hidden;
    overlfow-y: scroll;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 99;
    background-color: #FFF;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
    margin: 0 20%;
    display:none;
}
#search-list li {
    border-bottom: solid 1px #DCDADA;
    padding: 15px;
}

#search-result-title {
    background-color: #f5f5f5;
    height: 40px;
    margin-top: 0;
    font-size: 1.2em;
    padding: 8px 15px;
}

/************************ END NAVBAR STYLE ************************/

/************************ BEGIN UNCATAGORIZEDD STYLE ************************/

.btn-padding {
    padding: 15px 0;
}
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-facebook,
.btn-facebook :hover{
    background-color: #3B5998;
    color: #FFFFFF !important;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

#avatar-upload {
    padding: 5px 0;
}
#no-user-info {
    font-size: 18px;
    padding: 25px 0;
}

#account-home {
    min-height: 450px;
    background-color: #F8F8F8;
    padding-top: 35px;
}
.favorite-title {
    font-size: 18px;
}
.favorite-info {
    padding: 2px 0;
}

#jMap {
    width: 550px;
    height: 400px;
    margin-top: 20px;
}

#icon-row {
    font-size: 3.5em;
    background-image: url("../img/sweeping-turn-ruts.jpg");
    background-size:cover;
    background-attachment: fixed;
}

.icon-caption {
    font-size:.3em !important;
    color: #FFFFFF !important;
}
.front-row {
    min-height: 25vh;
    padding:1vh 0;
    background-color:#F5F5F5;
}

.iw-container {
    text-align: center;
}

#top-navbar {
    z-index: 99;
    margin-bottom: 0;
    background-color: #282B30;
    border-radius: 0;
    border: none;
    color: #AAA;
}
#nav-logo {
    height: 30px;
    margin-top: -5px;
}
#home-map{
    width: 100%;
    height: 60vh;
}
#state-map {
    width:100%;
    height:100%;
    position:fixed !important;
}

#top-map {
    width: 100%;
    height: 100%;
    position:fixed !important;
    top:0;
    left:0;
    z-index:-1;
}
#map-container,
#sat-map {
    width:100%;
    height:325px;
}
#state-map-container {
    width:100%;
}
.pad-map {
    margin-bottom:10px;
}

.row.add-edit-row {
    margin-top: 60px;
    margin-bottom: 60px;
}
#editor-search {
    margin:25px 0;
}


#home-page h2 {
    font-weight:300;
    color:#444;
}
#home-page p {
    font-size:1.25em;
    line-height:1.5em;
    color:#888;
    padding-right:15px;
    font-weight:300;
}
#home-page ul {
    font-size:1.25em;
    line-height:1.5em;
    color:#888;
}
.iw-title h5,
.iw-title a {
    color: #000;
    font-weight: bold;
    font-size: 1.2em;
}
#state-list {
    line-height:2.0em !important;
}
/************************ END UNCATAGORIZEDD STYLE ************************/





/************************ BEGIN FOOTER STYLE ************************/
#footer{
    width:100vw;
    padding: 20px 0;
    color:#AAA;
    border-bottom: 1px solid #3C3C3C;
    background-color: #282B30;
    position:relative;
}
#footer_top{
    margin:50px;
}
#footer_bottom {
    background-color: #2C2D33;
    height: 75px;
    position: absolute;
    width: 100vw;
    padding-top: 30px;
}
#copy_box {
    font-size: 0.8em;
    text-align: center;
}
/************************ END FOOTER STYLE ************************/



/************************ BEGIN TRACK PROFILE STYLE ************************/
#track-container {
    padding-left:0;
    padding-right:0;
}
#twapper{
    background-color:#EEE;
    min-height:55%;
    width:100vw;
}
#track-header{
    text-align: center;
    height: 275px;
    background-color: transparent;
    padding: 25px;
    width:100vw;
    background-size:cover;
}
#track-header h1{
    font-size:64px;
}
div#latlong {
    font-size: 0.85em;
    text-align: right;
    margin-top: -1.6em;
}
#about_me {
    min-height: 150px;
}
.all-wide {
    width:100%;
}
.showing-less {
    max-height: 350px;
    overflow-y: hidden;
}

#expandDirections {
    margin-top: 15px;
}


/** WEATHER START **/
.inline-block {
    display: inline-block !important;
}
.text-left {
    text-align: left !important;
}
.margin-bottom-10 {
    margin-bottom: 10px !important;
}
.font-size-30 {
    font-size: 30px !important;
}
.weather-font {
    font-size: 2.3em;
}
#weather-list {
    width: 100%;
    text-align: center;
    padding: 5px;
}
.padding-25 {
    padding: 25px !important;
}
.white {
    color: #fff !important;
}
.font-size-50 {
    font-size: 50px !important;
}
.bg-blue-600 {
    background-color: #337ab7 !important;
}
/** WEATHER END**/




.extforcast {
    font-size: 20px;
}
.forecast-list {
    margin: 2px 10px;
    background-color: #efefef;
    width: 16%;
}
.adp-summary {
    font-size: 2.25em;
}

.adp-placemark,
.adp-details,
.adp-legal{
    display: none;
}

.white-link {
    color:#FFF;
    text-decoration:none;
}

#header_overlay {
    width: 100%;
    position: absolute;
    left: 0;
    background-color: rgb(0, 0, 0);
    opacity: 0.75;
    top: 50px;
    height: 275px;
}
#track-head-buttons {
    margin: 15px;
}
#header-map {
    z-index:-1;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
}


#contact-form .form-group {
    margin-bottom:15px;
}

.g-recaptcha {
    margin: 5px 0;
}

#contact-ribbon {
    text-align: center;
    padding: 15px 0;
    min-height: 50px;
    background: rgb(255, 255, 255) none repeat scroll 0 0;

}
#track-main {
    padding: 25px;
    min-height: 350px;
    background-color: #F8F8F8;
}
/************************ END TRACK PROFILE STYLE ************************/




/************************ ADD NEW TRACK STYLE BEGIN ************************/
#about {
    height:200px;
}
#setPin {
    margin-top:-190px;
    margin-right:15px;
}

/************************ ADD NEW TRACK STYLE END ************************/




/************************ TRACK EDITOR PROFILE STYLE ************************/
.track-edit-group {
    margin:60px;
}
/************************ END EDITOR PROFILE STYLE ************************/



/*BELOW YOULL FIND THE SHIT FOR THE MAP'S TO FILTER BAR*/
#filter-bar {
    min-height:48px;
    background-color:#000;
    color:#FFF;
    font-weight:bold;
    padding-left:15px;
    padding-right:15px;
}

#filter-bar a {
    color:#FFF;
}

#filter-bar a:hover,
#filter-bar a:focus,
.filter_on {
    color:#337ab7 !important;
}

#filter-bar li {
    list-style:none;
    display:inline-block;
    margin:15px 3px 0 3px;

}

#left-filters {
    float:left;
    margin-left:-45px;
}
#right-filters {
    float:right;
}

#find_all_filter,
#all-li {
    display:none !important;
}



/*********************** BEGIN SIDEBAR *****************************/
.inforow {
    margin-right:0 !important;
}
.info-win-side{
    list-style:none;
    margin-left:-15px;
    background-color:#F5F5F5;
    padding: 8px 0;
}
.info-win-side:nth-child(odd) {
    background-color: #FFF
}
.infowin li {
    margin-right: 0;
    padding-left: 15px;
    width: 375px;
    padding-right: 45px;
    margin-left: -15px;
}

.infowin p {
    font-size:12px;
}

#sidebar_container {
    z-index:1;
    display:inherit;
    position:fixed;
    left:0;
    top:98px;
    width: 375px;
    height: 100%;
    overflow:auto;
    background-color:#FFF;
}

#sidebar_container::-webkit-scrollbar { width: 0 !important }
#sidebar_container { -ms-overflow-style: none; }
#sidebar_inner {
    margin-left:-25px;
}



#toggle_button {
    z-index:1;
    position:fixed;
    cursor: pointer;
    padding:10px;
    top:103px;
    left:375px;
    border:1px solid #FFF;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color:#FFF;
}
#toggle_arrow {
    font-weight:600;
}
#toggle_arrow:hover{
    transform: scale(1.25);
}
#mobile_ribbon {
    display:none;
}
/*********************** END SIDEBAR *****************************/





/*************************** BEGIN ADMIN STYLE ********************/
.user-email {
    color: #d9534f;
}
.verified-email {
    color:#5cb85c;

}

/*************************** END ADMIN STYLE ********************/




/************************** BEGIN Style for Login/Registration pages***************************/
#back-home {
    position: fixed;
    z-index: 99;
    font-size: 1.5em;
    right: 25px;
    top: 25px;
}
#back-home a {
    color:#FFF;
}
.nopad{
    padding:0;
}

.log-sign-wrap {
    background-image: url("../img/sweeping-turn-ruts.jpg");
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    margin-top:-50px;
}
.log-sign-wrap h2,
.log-sign-wrap label {
    color:#FFF;
}
#login-container {
    margin-top: 15vh;
    z-index:2;
    opacity:1;
    color:#FFF;
}
.form-group {
    margin-bottom:0;
}
.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}
.form-signin .checkbox {
    font-weight: normal;
}
.form-signin .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
    z-index: 2;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="email"] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
#register-button {
    margin-top:10px;
}
/************************** END Style for Login/Registration pages***************************/



@media screen and (max-width: 767px) {

    #mobile_ribbon {
        position: fixed;
        display: block;
        z-index: 99;
        bottom: 0;
        left: 0;
        height: 45px;
        width: 25%;
        opacity: 0.85;
        background-color: #222;
        color: #FFF;
        font-weight: bold;
        text-align: center;
        padding-top: 10px;
        font-size: 16px;
        float: right;
        margin-left: 40%;
        cursor: pointer;
        border-top-right-radius: 2em;
        border-top-left-radius: 2em;
    }
    .dropdown-menu > li > a {
        color: #FFF !important;
    }
    #search-wrapper,
    #toggle_button {
        display:none;
    }

    #sidebar_container {
        width: 100vw;
        top: 100vh;
        position: fixed;
    }
    #track-header h1 {
        font-size: 48px;
    }
    #footer_top{
        margin:5px;
    }
}

@media only screen and (max-width: 480px) {
    #left-filters {
        display:none;
    }
    #right-filters {
        float: none;
    }
    #jMap {
        width: 300px;
        height: 215px;
        margin-top: 10px;
    }
    #track-main {
        padding: 25px 5px;
    }
}


